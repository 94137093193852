import React = require('react');
import Banner from '../../scenes/Quote/components/Banner';
import { get } from 'lodash';
import { IOffer } from '../../data/api/models';
import { IAuthActions } from '../../services/auth/reducer';
import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

interface IBannerData {
  offers: IOffer,
  authActions: IAuthActions,
  isCompletePage?: boolean,
}

export const AdmiralHomeInsuranceBanner = ({ offers, isCompletePage = false, authActions }: IBannerData) => {

  const [loading] = useState(false);

  const deepLink = get(offers, 'household.deeplink');
  const premium = get(offers, 'household.premium');
  const tierName = get(offers, 'household.tier_name');
  const coverType = get(offers, 'household.cover_type');
  const name = get(offers, 'household.name');

  if (!premium && !deepLink) {
    return null;
  }

  const S3Link = window.__APP_PROD__ ? 'https://admiral-prod.s3.eu-west-1.amazonaws.com/images/global/household/' : 'https://admiral-uat.s3.eu-west-1.amazonaws.com/images/global/household/';

  const handleRetrieveQuote = () => {
    const urlPath = new URL(deepLink);
    const urlParams = new URLSearchParams(urlPath.search);
    const value = urlParams.get('quoteKey')
    const baseUrl = window.__HOUSEHOLD_RETRIEVE_URL__;
    const retrieveUrl = baseUrl + `?quoteKey=${value}&tier=${tierName}&brand=Admiral`
    window.open(retrieveUrl, '_blank');
  }

  return (
    <div className={'admiral-home-insurance-banner-container'}>

      <Banner
        imageDesktop={S3Link + 'household-banner-desktop.jpg'}
        imageDesktopx2={S3Link + 'household-banner-desktop@2x.jpg'}
        imageDesktopx2Width={1400}
        imageMobile={S3Link + 'household-banner-mobile.jpg'}
        imageMobilex2={S3Link + 'household-banner-mobile@2x.jpg'}
        imageMobilex2Width={300}
        link={null}
        alt={'Admiral Home Insurance'}
      />

      <>
        <div className={isCompletePage ? 'dynamic-text-container-complete' : 'dynamic-text-container'}>
          <div className={isCompletePage ? 'image-text-container-complete' : 'image-text-container'}>
            <div className={isCompletePage ? 'customer-greeting-complete' : 'customer-greeting'}>Hello {name},</div>
            <div className={isCompletePage ? 'image-text-complete' : 'image-text'}>Get Home Insurance with Admiral
              from
              only <strong>£{premium}</strong>
            </div>
          </div>

          <div className={isCompletePage ? 'quote-button-container-complete' : 'quote-button-container'}>

            {loading && (
              <div style={{ display: 'flex', alignItems: 'center', color: '#505050' }}>
                <FaSpinner className="fa-spin" style={{ fontSize: '2.5em' }}/>
                <div style={{ marginLeft: '15px', fontStyle: 'italic' }}>
                  <div>Retrieving you quote</div>
                  <div>pls wait…</div>
                </div>
              </div>
            )}

            {!loading && (
              <div className={isCompletePage ? 'quote-button-complete' : 'quote-button'}>
                <div className={isCompletePage ? 'quote-button-link-complete' : 'quote-button-link'}><img
                  src={require('../../scenes/Quote/img/button.svg')}
                  onClick={handleRetrieveQuote}/></div>
              </div>
            )}

          </div>

          <div className={isCompletePage ? 'image-text-footer-complete' : 'image-text-footer'}>
            <div>Your home price, for our {tierName} tier for {coverType} cover, is based on assumptions.</div>
            <div>Price may change depending on your cover needs and eligibility. Price includes IPT.</div>
          </div>
        </div>
      </>
    </div>
  );
};
