import * as React from 'react';
import { flow, forEach, get } from 'lodash';
import { IBrandingState, II18nMessages } from '../../../../services/branding/models';
import { ICustomer, IOffer, IPremium, IProduct, IQuotes } from '../../../../data/api/models';
import { connect } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IStore } from '../../../../redux/IStore';
import { ICompleteData, IQuoteActions } from '../../../../services/quote/models';
import { formValueSelector, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { actions as authActions, IAuthActions } from '../../../../services/auth/reducer';
import { IAuthState } from '../../../../services/auth/models';
import { Link } from 'react-router-dom';
import Heap from '../../../../helpers/Heap';
import { bindActionCreators } from 'redux';
import { AdmiralHomeInsuranceBanner } from '../../../../components/AdmiralHomeInsuranceBanner';
import DataLayer from '../../../../helpers/DataLayer';

interface ICompleteProps extends InjectedFormProps {
  i18n: II18nMessages;
  product: IProduct;

  renderDefinition(name: string): any;

  auth: IAuthState;
  data: ICompleteData;
  customer: ICustomer;
  quotes: IQuotes;
  branding: IBrandingState;
  premium: IPremium;
  offers: IOffer;
  actions: {
    quote: IQuoteActions;
    push(path: string);
    auth: IAuthActions;
  };
}

class IComplete extends React.Component<ICompleteProps, any> {

  constructor(props) {
    super(props);
    const { data, branding } = props;
    Heap.trackEvent('PurchaseSuccess', get(props, 'formValues'), 'quote')
    const isBannerEnabled = get(branding, 'channel.products[0].metadata.direct_customer_mta_options.offers.household_banner.enabled', false)
    const policyId = get(data, 'policy_id');
    const leadTravellerEmail = get(props, 'formValues.travellers.[0].email');
    const isAggregator = get(branding, 'channel.channelType') === 'AGG';
    const communicationPref = get(props, 'formValues.communications.post', false) === false ? 'online' : 'post';
    if (!isAggregator) {
      DataLayer.push({
        'purchaseSuccess': 'true',
        'policyNumber': data.policy_number,
        'leadTravellerEmail': leadTravellerEmail,
        'documentPreferences': communicationPref,
      })
    }

    if (policyId && isBannerEnabled) {
      props.actions.auth.getHouseholdOffer(policyId);
    }

  }

  private renderTrackingItems = () => {
    const { branding, premium, data } = this.props;

    const items = [];

    if (branding.aggregatorTracking.type === 'cyti') {
      const params = branding.aggregatorTracking.cyti;
      const cytiUrl = branding.aggregatorTracking.cyti.url + '/track/confirm';

      items.push(
        <img
          key={0}
          // tslint:disable-next-line:max-line-length
          src={`${cytiUrl}?cref=${params.cref}&policyreference=${data.policy_number}&premium=${premium.gross}&t=${params.token}`}
          height="0"
          width="0"
        />,
      );
    }

    if (branding.aggregatorTracking.type === 'idol') {
      const params = branding.aggregatorTracking.idol;
      const url = 'https://quotes.theidol.com/v1/travel/pixels/provisionalSales/admiral';

      items.push(
        <img
          key={0}
          src={`${url}?ref=${params.reference}&fullprem=${premium.gross}`}
          height="0"
          width="0"
        />,
      );
    }

    return items;
  }

  public render() {
    const { data, auth: { isAuthenticated }, offers, actions } = this.props;

    return (
      <Form>
        <div className="steps-container">
          <div className="section-title">
            <h3 className={'purchase-complete'}>Purchase Complete</h3>
          </div>

          <div className="section-group">
            <div className="section">
              <Row>
                <Col xs={6}><b>Your policy number</b></Col>
                <Col xs={6} style={{ textAlign: 'right' }}><span
                  className="policy-number">{data.policy_number}</span></Col>
              </Row>

              <Row>
                <Col xs={6}>
                  <div style={{ margin: '15px 0', fontWeight: 'bold' }}>What happens now?</div>
                </Col>
                {isAuthenticated && (
                  <Col xs={6} style={{ textAlign: 'right', paddingTop: '10px' }}>
                    <Link to={`/account/policies`}>
                      <Button bsStyle="primary">Back To Account</Button>
                    </Link>
                  </Col>
                )}
              </Row>

              <div className="section" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div>
                  {data.documents_post ? (
                    <div className={'purchase-complete-text'}>
                      Your documents will be posted to you in 5-7 working days. You can also view your documents by
                      selecting them below. Please make sure you check them carefully to ensure all details are
                      correct,
                      and note down key contact numbers before you travel.
                    </div>
                  ) : (
                    <div className={'purchase-complete-text'}>
                      You'll be able to access your documents from your Travel Portal account at any point in time.
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix"/>
            </div>
          </div>

          <div className="section-group">
            <div className="section">
              <Row>
                <Col xs={12} style={{ marginBottom: '15px' }}><b>Register Now!</b></Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div>Our online portal has recently been improved and has new functionality. It may be faster and
                    easier to:
                  </div>
                  <ul style={{ marginLeft: '15px' }}>
                    <li>View and download your documents</li>
                    <li>Change your travel plans: dates and destinations</li>
                    <li>Add travellers</li>
                    <li>Update your medical history</li>
                    <li>Add extra cover, and more!</li>
                  </ul>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  Shortly you will be receiving an email that will give you instructions on how to use your Travel
                  Portal moving forward.
                </Col>
              </Row>

              <div className="clearfix"/>
            </div>
          </div>

          <div className="section-group">
            <div className="section">
              <Row>
                <Col xs={12} style={{ marginBottom: '15px' }}><b>Accessibility and Support</b></Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div>We want to make sure it’s as easy as possible for everyone to use our products and services.
                    We
                    are continuously trying to improve our accessibility for people who have disabilities, long term
                    health conditions, or are struggling with challenging times. For more information visit our
                    <a href={'https://www.admiral.com/accessibility'} target={'_blank'}> accessibility and
                      support</a> page.
                  </div>
                </Col>
              </Row>
              <div className="clearfix"/>
            </div>
          </div>

          <AdmiralHomeInsuranceBanner offers={offers} isCompletePage={true} authActions={actions.auth}/>

          {this.renderTrackingItems()}
        </div>
      </Form>
    );
  }
}

let selector;

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const { quotes } = state.quote;

      let premium: IPremium = null;

      const values = selector(
        state,
        'tripType',
        'schemeId',
        'quoteReference',
      );

      const formValues = getFormValues('quote')(state);

      if (state.quote.quotes) {
        forEach(state.quote.quotes, (tripTypes, level) => {
          forEach(tripTypes, (quotedPremium: IPremium) => {
            if (!quotedPremium) {
              return null;
            }

            if (quotedPremium.scheme.id === values.schemeId) {
              premium = quotedPremium;
            }
          });
        });
      }

      return {
        auth: state.auth,
        data: state.quote.complete,
        formValues,
        quotes,
        branding: state.branding,
        premium,
        offers: state.auth.offers,
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        auth: bindActionCreators({ ...authActions }, dispatch),
      }
    }),
  ),
])(IComplete);
