import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.scss';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import FieldFormControl from '../../../../components/FieldFormControl';
import { isNotFalse, isRequired } from '../../../../util/validators';

interface ISignpost {
  medical?: boolean;
  type?: SignpostType;
  callCenter?: boolean;
  withCheckbox?: boolean;
  checkBoxFormPath?: string;
  withLink?: boolean;
}

export const enum SignpostType {
  TYPE_A = 'A',
}

class Signposting extends React.Component<ISignpost> {

  private renderContent = () => {
    const { medical, callCenter, withLink } = this.props;
    if (medical) {
      return this.renderMedicalDeclineContent();
    } else if (callCenter) {
      return this.renderContactCallCenterContent(withLink);
    } else {
      return this.renderMedicalOverThresholdContent();
    }
  }

  private renderConfirmCheckbox = () => {
    const { checkBoxFormPath } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <Field
            name={checkBoxFormPath}
            type="checkbox"
            value="true"
            component={FieldFormControl}
            validate={[isRequired, isNotFalse]}
          >
            <div/>
            Please confirm that you have read and understood the information above.
          </Field>
        </Col>
      </Row>
    );
  }

  private renderDirectoryDetails = () => {
    return (
      <div className="directory-details">
        <Row>
          <Col xs={12} style={{ textDecoration: 'underline' }}>
            <h4><strong>The MoneyHelper directory</strong></h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            If you would like to access this directory just visit:
          </Col>
        </Row>
        <ul>
          <li>
            Website: <a target={'_blank'}
                        href={'https://www.moneyhelper.org.uk/en/everyday-money/insurance/use-our-travel-insurance-directory'}>
            https://www.moneyhelper.org.uk/en/everyday-money/insurance/use-our-travel-insurance-directory
          </a>
          </li>
          <li>
            Telephone: 0800 138 7777
          </li>
          <li>
            Opening hours: Monday to Friday 8am to 6pm
          </li>
        </ul>
      </div>
    );
  }

  private renderMedicalDeclineContent = () => {
    const { type } = this.props;
    let component = null;

    switch (type) {
      case SignpostType.TYPE_A:
        component = (
          <Row className="medical-decline">
            <Col xs={12}>
              <h3 className="title">UNABLE TO OFFER A QUOTE</h3>
              <p>
                Unfortunately, we are unable to provide you with a quote at this time,
                at least partly due to the medical conditions declared.
              </p>
              <p>
                The <strong>MoneyHelper</strong> travel insurance directory has been created for anyone who may have
                been refused Travel Insurance cover due
                to their medical conditions, to allow them to shop around for alternative providers.
              </p>
              {this.renderDirectoryDetails()}
              <p>To request the directory details in writing, please
                click <a target={'_blank'} href={'https://eforms.admiral.com/travel-medical-decline'}>here</a>.
              </p>
              <p>
                Bear in mind if you’re insuring multiple travellers on separate policies, you may not be covered for
                claims related to the pre-existing medical conditions of someone not named on your policy.
              </p>
            </Col>
          </Row>
        );
        break;
    }

    return component;

  }

  private renderMedicalOverThresholdContent = () => {
    const { type, withCheckbox } = this.props;
    let component = null;

    switch (type) {
      case SignpostType.TYPE_A:
        component = (
          <div className="medical-threshold">
            <div className="content">
              <h3 className="title">FOR YOUR INFORMATION</h3>
              <p>
                Your quote includes an additional premium due to the medical conditions declared.
                You may find cheaper or more suitable cover using the <strong>MoneyHelper</strong> travel insurance
                directory.
                This has been created for anyone who may have received a high additional premium due to their
                medical conditions, to allow them to shop around for alternative providers.
              </p>
              {this.renderDirectoryDetails()}
              <p>To request the directory details in writing, please
                click <a target={'_blank'} href={'https://eforms.admiral.com/travel-medical-load'}>here</a>.
              </p>
              <p>
                Bear in mind if you’re insuring multiple travellers on separate policies, you may not be covered for
                claims related to the pre-existing medical conditions of someone not named on your policy.
              </p>
              {withCheckbox && (
                <>
                  {this.renderConfirmCheckbox()}
                </>
              )}
            </div>
          </div>
        );
        break;
    }
    return component;
  }

  private renderContactCallCenterContent = (withLink = true) => {
    return (
      <>
        <div className="call-center">
          <div>
            <h3 className="title">WHOOPS!</h3>
            <p>
              Unfortunately, we have been unable to make the change you have requested.
            </p>
            <p>
              Please contact us in order to make the change and keep your policy up to date. Details on how to contact
              us can be found <a target={'_blank'} href={'https://www.admiral.com/contact-us/travel'}> here </a>
            </p>
            <p>
              It is important that your details are kept up to date, as incorrect information could invalidate your
              cover or affect any future claims.
            </p>
          </div>
        </div>
        {withLink && (
          <div className="return-to-account-btn mt-15">
            <Link to={`/account/policies`}>
              Return to Policies
            </Link>
          </div>
        )}
      </>
    );
  }

  public render() {
    return (
      <>
        {this.renderContent()}
      </>
    );
  }

}

export default Signposting;
