import * as React from 'react';
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
  InjectedFormProps,
  reduxForm,
  submit
} from 'redux-form';
import { flow, get, isEmpty } from 'lodash';
import { II18nMessages } from '../../../../services/branding/models';
import { Button, Form } from 'react-bootstrap';
import Travellers from './components/Travellers';
import { connect } from 'react-redux';
import {
  IChannel,
  IDestination,
  IIndustry,
  IOccupation,
  IPolicyApplication,
  IProduct,
  ITraveller,
} from '../../../../data/api/models';
import { isRequired } from '../../../../util/validators';
import * as moment from 'moment';
import { IAuthState } from '../../../../services/auth/models';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { bindActionCreators } from 'redux';
import VoucherCode from '../../components/VoucherCode';
import { IStore } from '../../../../redux/IStore';
import ErrorFormControl from '../../../../components/ErrorFormControl';

interface ITravellersDetailsFormProps extends InjectedFormProps {
  destinations: IDestination[];
  occupations: IOccupation[];
  industries: IIndustry[];
  auth: IAuthState;
  i18n: II18nMessages;
  groupType: string;
  tripType: string;
  startDate: string;
  region: string;
  maxTripDuration: string;
  endDate: string;
  travellers: ITraveller[];

  dispatch(event: any);

  product: IProduct;
  destinationsValue: string[];
  occupationValue: string[];
  industryValue: string[];
  channel: IChannel;
  quote: IPolicyApplication;
  actions: {
    submit(name: string);
  };
  synchronousError: any;

  renderDefinition(name: string): any;
}

class TravellersDetailsForm extends React.Component<ITravellersDetailsFormProps, any> {
  public componentWillMount() {
    const { change, auth, dispatch } = this.props;

    // if (quote.campaignCode) {
    //   dispatch(change('campaignCode', quote.campaignCode));
    // }

    const dateFromApi = (date) => {
      if (!date) {
        return '';
      }

      const parsedDate = moment(date).format('DD/MM/YYYY');

      return parsedDate !== 'Invalid Date' ? parsedDate : '';
    };

    if (auth.isAuthenticated) {
      dispatch(change('travellers[0].title', auth.customer.title));
      dispatch(change('travellers[0].dob', dateFromApi(auth.customer.dob)));
      dispatch(change('travellers[0].firstName', auth.customer.firstName));
      dispatch(change('travellers[0].lastName', auth.customer.lastName));
      dispatch(change('travellers[0].email', auth.customer.email));
      dispatch(change('travellers[0].phone', auth.customer.phone1));
      dispatch(change('travellers[0].address.postcode', auth.customer.address.postcode));
      dispatch(change('travellers[0].address.line1', auth.customer.address.line1));
      dispatch(change('travellers[0].address.line2', auth.customer.address.line2));
      dispatch(change('travellers[0].address.town', auth.customer.address.town));
      dispatch(change('travellers[0].address.county', auth.customer.address.county));
      dispatch(change('travellers[0].address.country', auth.customer.address.country));
    }
  }

  private isRenewal() {
    return this.props.quote.application.quoteType === 'renewal';
  }

  private checkIfAddressLinePostcodeIsPopulated = () => {
    if (this.props.travellers && this.props.travellers.length &&
      this.props.travellers[0] && this.props.travellers[0].address) {
      return this.props.travellers[0].address.line1 === '';
    }
  }

  private checkForErrors = () => {
    const { actions, synchronousError } = this.props;
    Promise.resolve(actions.submit('quote')).then(() => {
      if (synchronousError && !isEmpty(synchronousError)) {
        window.scrollTo(0, 0);
      }
    });
  }

  public render() {
    const {
      i18n,
      submitting,
      groupType,
      handleSubmit,
      occupations,
      industries,
      valid,
      product,
      industryValue,
      renderDefinition,
      submitFailed,
      change,
    } = this.props;

    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        {isRequired && submitFailed && !valid && (
          <div className="error-block-container" id={'error-container'}>
            <h1>Whoops!</h1>
            <p>Missing something? Please check the areas marked in orange before you continue.</p>
          </div>
        )}
        <div className="steps-container">
          <FieldArray
            name="travellers"
            i18n={i18n}
            product={product}
            component={Travellers}
            groupType={groupType}
            occupations={occupations}
            industries={industries}
            industryValue={industryValue}
            renderDefinition={renderDefinition}
            change={change}
            isRenewal={this.isRenewal()}
          />

          <Field
            name={`errorMessage`}
            component={ErrorFormControl}
            showMessage={submitFailed && !valid}
            fontColor={'#FF7911'}
          />

          {!this.isRenewal() && (
            <VoucherCode {...this.props}/>
          )}

          <div className="btn-bar">
            <Button
              bsStyle="primary"
              id="continue"
              type="submit"
              bsSize="lg"
              className="pull-right"
              onClick={this.checkForErrors}
              disabled={submitting ||
                (submitFailed && !valid) ||
                (valid && this.checkIfAddressLinePostcodeIsPopulated())}
            >
              CONTINUE
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {
    errorMessage: null,
    travellers: [
      {
        address: {
          postcode: null
        }
      }
    ]
  };
  if (!get(values, 'travellers[0].address.line1', null)) {
    errors.travellers[0].address.postcode = 'Please use the Lookup button to select an address.';
  }

  if (get(values, 'groupType') === 'group' && get(values, 'travellers', []).length === 1) {
    errors.errorMessage = 'Warning - You cannot continue without declaring at least 2 travellers on a group policy. Please click on \"Add a Adult\" or \"Add a Child\" buttons to add more travellers'
  }

  return errors;
};

let selector;

export default flow([
  reduxForm({
    form: 'quote',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const groupType = selector(state, 'groupType');
      const startDate = selector(state, 'startDate');
      const endDate = selector(state, 'endDate');
      const destinationsValue = selector(state, 'destinations');
      const occupationValue = selector(state, 'occupations');
      const industryValue = selector(state, 'industries');
      const synchronousError = getFormSyncErrors('quote')(state);
      const region = selector(state, 'region');
      const maxTripDuration = selector(state, 'maxTripDuration');
      const travellers = selector(state, 'travellers');

      return {
        region,
        maxTripDuration,
        groupType,
        startDate,
        endDate,
        destinationsValue,
        occupationValue,
        industryValue,
        travellers,
        auth: state.auth,
        quote: state.quote,
        synchronousError,
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        submit: bindActionCreators(submit, dispatch),
      },
    }),
  ),
])(TravellersDetailsForm);
