import { ICompleteData, IQuoteAction, IQuoteActions, IQuoteCalculateOptions, IQuoteState, QuoteObject } from './models';
import * as api from '../../data/api/api';
import * as uuid from 'uuid/v4';
import {
  ICustomer,
  IFraudCheckRequest,
  IOptionMap,
  IPolicy,
  IPolicyApplication,
  IPremium,
  IProduct,
  IQuotes,
  ITraveller,
} from '../../data/api/models';
import { IStore } from '../../redux/IStore';
import { change, destroy, getFormValues } from 'redux-form';
import { cloneDeep, filter, find, forEach, get, isEmpty } from 'lodash';
import { recalculateScore } from '../healix/reducer';
import { IBrandingState } from '../branding/models';
import axios from 'axios';
import * as moment from 'moment';
import { quoteHasMedical } from '../../helpers/signpost';
import { push } from 'react-router-redux';
import { url } from '../../data/api/util';
import { Endpoints } from '../../data/api/endpoints';
import Heap from '../../helpers/Heap';
import { ICoverDetailsForm } from '../mta/models';

const initialState: IQuoteState = {
  isCalculating: false,
  initialised: false,
  isSubmitting: false,
  application: {
    id: '',
    tripType: null,
    groupType: null,
    startDate: '',
    endDate: '',
    destinations: [],
    stateCities: [],
    region: '',
    occupation: '',
    industry: '',
    quotationDeclaration: false,
    travellers: [
      {
        id: uuid(),
        title: '',
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          town: '',
          county: '',
          postcode: '',
        },
        type: 'adult',
        screening: null,
        screeningSessionId: '',
        marketingOptions: {
          email: 1,
          phone: 1,
          post: 1,
          sms: 1,
        },
        metadata: null,
      },
    ],
    medicalDeclaration: [],
    options: {
      winter_sports: null,
      cruise_cover: null,
      hazardous_activities: false,
      gadget_cover: null,
      excess_waiver: null,
      golf_cover: null,
    },
    schemeId: '',
    brokerId: '',
    quoteReference: '',
    payOnAccount: false,
    steps: ['tripDeclaration'],
    generateDocuments: false,
    emailSend: false,
    claims: undefined,
    criminal: undefined,
    campaignCode: null,
    calculateAllSchemes: false,
    communications: {
      post: false,
    },
    fraudCheck: {
      response: null,
    },
    errorMessage: '',
  },
  destinations: [],
  stateCities: [],
  occupations: [],
  customer: {},
  industries: [],
  quotes: {},
  policy: null,
  complete: null,
  isRetrievingQuote: false,
  errorMessage: null,
  voucherCodeStatus: '',
  voucherCodeMessage: '',
  voucherCodePreventDisplay: false,
  campaignCode: null,
  waitForPurchaseToComplete: false,
  upSellIncreaseRatio: {
    admiral: 1,
    gold: 1,
    platinum: 1,
  },
  signpost: { isSignposted: false, isMedical: false },
  selectedPremium: null,
};

const INIT = 'b2c/quote/INIT';
const SET_STEPS = 'b2c/quote/SET_STEPS';
const SET_APPLICATION = 'b2c/quote/SET_APPLICATION';
const SET_CUSTOMER = 'b2c/quote/SET_CUSTOMER';
const SET_DESTINATIONS = 'b2c/quote/SET_DESTINATIONS';
const SET_STATE_CITIES = 'b2c/quote/SET_STATE_CITIES';
const SET_OCCUPATIONS = 'b2c/quote/SET_OCCUPATIONS';
const SET_INDUSTRIES = 'b2c/quote/SET_INDUSTRIES';
const SET_CHANNELS = 'b2c/quote/SET_CHANNELS';
const SET_IS_CALCULATING = 'b2c/quote/SET_IS_CALCULATING';
const SET_QUOTES = 'b2c/quote/SET_QUOTES';
const SET_QUOTE_STEPS = 'b2c/quote/SET_QUOTE_STEPS';
const SET_POLICY = 'b2c/quote/SET_POLICY';
const SET_BROKER_ID = 'b2c/quote/SET_BROKER_ID';
const SET_CAMPAIGN_CODE = 'b2c/quote/SET_CAMPAIGN_CODE';
const COMPLETE = 'b2c/quote/COMPLETE';
const COMPLETE_DOCUMENTS = 'b2c/quote/COMPLETE_DOCUMENTS';
const RESET = 'b2c/quote/RESET';
const RETRIEVE_QUOTE = 'b2c/quote/RETRIEVE_QUOTE';
const IS_RETRIEVE_QUOTE = 'b2c/quote/IS_RETRIEVE_QUOTE';
const RETRIEVE_QUOTE_FOR_JOURNEY = 'b2c/quote/RETRIEVE_QUOTE_FOR_JOURNEY';
const UPDATE_APPLICATION = 'b2c/quote/UPDATE_APPLICATION';
const HAS_TERMINAL_CONDITION = 'b2c/quote/b2c/quote/HAS_TERMINAL_CONDITION';
const SET_ERROR_MESSAGE = 'b2c/quote/b2c/quote/SET_ERROR_MESSAGE';
const VOUCHER_CODE_SUCCESS = 'b2c/quote/VOUCHER_CODE_SUCCESS';
const VOUCHER_CODE_ERROR = 'b2c/quote/VOUCHER_CODE_ERROR';
const VOUCHER_CODE_RESET = 'b2c/quote/VOUCHER_CODE_RESET';
const DISPATCH_CAMPAIGN_CODE = 'b2c/quote/DISPATCH_CAMPAIGN_CODE';
const SET_FRAUD_CHECK = 'b2c/quote/SET_FRAUD_CHECK';
const STORE_UP_SELL_INCREASE_RATIOS = 'b2c/quote/STORE_UP_SELL_INCREASE_RATIOS';
const SET_WAIT_FOR_PURCHASE_TO_COMPLETE = 'b2c/quote/SET_WAIT_FOR_PURCHASE_TO_COMPLETE';
const STORE_CPR_RESPONSE = 'b2c/quote/STORE_CPR_RESPONSE';
const SET_SIGNPOST = 'b2c/quote/SET_SIGNPOST';
const SET_SELECTED_PREMIUM = 'b2c/quote/SET_SELECTED_PREMIUM';
const SET_IS_SUBMITTING = 'b2c/quote/SET_IS_SUBMITTING';
const SET_QUOTE_APPLICATION_PREMIUM = 'b2c/quote/SET_QUOTE_APPLICATION_PREMIUM';

export function reducer(state: IQuoteState = initialState, action: IQuoteAction): IQuoteState {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        initialised: true,
        application: {
          ...action.payload,
        },
      };
    case SET_DESTINATIONS:
      return {
        ...state,
        destinations: action.payload.destinations,
      };
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case SET_STATE_CITIES:
      return {
        ...state,
        stateCities: action.payload.stateCities,
      };
    case SET_OCCUPATIONS:
      return {
        ...state,
        occupations: action.payload.occupations,
      };
    case SET_INDUSTRIES:
      return {
        ...state,
        industries: action.payload.industries,
      };
    case SET_CHANNELS:
      return {
        ...state,
        channels: action.payload.channels,
      };
    case SET_APPLICATION:
      return {
        ...state,
        application: action.payload.application,
        customer: action.payload.application.customer,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer,
      };
    case SET_IS_CALCULATING:
      return {
        ...state,
        isCalculating: action.payload.isCalculating,
      };
    case SET_QUOTES:
      return {
        ...state,
        quotes: action.payload.quotes,
      };
    case SET_QUOTE_STEPS:
      return {
        ...state,
        application: {
          ...state.application,
          steps: action.payload,
        },
      };
    case SET_POLICY:
      return {
        ...state,
        policy: action.payload.policy,
      };
    case SET_BROKER_ID:
      return {
        ...state,
        application: {
          ...state.application,
          brokerId: action.payload,
        },
      };
    case SET_CAMPAIGN_CODE:
      return {
        ...state,
        campaignCode: action.payload.campaignCode,
      };
    case DISPATCH_CAMPAIGN_CODE:
      return {
        ...state,
        application: {
          ...state.application,
          campaignCode: action.payload,
        },
      };
    case COMPLETE:
      return {
        ...state,
        complete: action.payload,
        errorMessage: null,
      };
    case COMPLETE_DOCUMENTS:
      return {
        ...state,
        complete: {
          ...state.complete,
          documents: action.payload,
        },
      };
    case SET_STEPS:
      return {
        ...state,
        application: {
          ...state.application,
          steps: action.payload.steps,
        },
      };
    case UPDATE_APPLICATION:
      return {
        ...state,
        application: {
          ...state.application,
          ...action.payload,
        },
      };
    case HAS_TERMINAL_CONDITION:
      return {
        ...state,
        application: {
          ...state.application,
          steps: ['tripDeclaration', 'tripDetails', 'travellersDetails', 'medicalDeclaration'],
        },
      };
    case RETRIEVE_QUOTE:
      return {
        ...state,
        isRetrievingQuote: true,
        application: action.payload,
      };
    case IS_RETRIEVE_QUOTE:
      return {
        ...state,
        isRetrievingQuote: action.payload,
      };
    case RETRIEVE_QUOTE_FOR_JOURNEY:
      return {
        ...state,
        initialised: true,
        isRetrievingQuote: true,
        application: action.payload.quoteObject,
      };
    case VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        voucherCodeStatus: 'success',
        voucherCodeMessage: 'Voucher code applied',
        voucherCodePreventDisplay: action.payload.preventDisplay,
        regexVoucherCode: action.payload.regexVoucherCode,
      };
    case VOUCHER_CODE_ERROR:
      return {
        ...state,
        application: {
          ...state.application,
          campaignCode: '',
        },
        voucherCodeStatus: 'error',
        voucherCodeMessage: 'Voucher code not found',
      };
    case VOUCHER_CODE_RESET:
      return {
        ...state,
        voucherCodeStatus: '',
        voucherCodeMessage: '',
        regexVoucherCode: false,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.message,
      };
    case RESET:
      return cloneDeep(initialState);
    case SET_FRAUD_CHECK:
      return {
        ...state,
        application: {
          ...state.application,
          fraudCheck: action.payload,
        },
      };
    case SET_QUOTE_APPLICATION_PREMIUM:
      return {
        ...state,
        application: {
          ...state.application,
          premiums: action.payload,
        },
      };
    case STORE_UP_SELL_INCREASE_RATIOS:
      return {
        ...state,
        upSellIncreaseRatio: action.payload,
      };
    case STORE_CPR_RESPONSE:
      return {
        ...state,
        cprResponse: action.payload,
      };
    case SET_WAIT_FOR_PURCHASE_TO_COMPLETE:
      return {
        ...state,
        waitForPurchaseToComplete: action.payload,
      };
    case SET_SIGNPOST:
      return {
        ...state,
        signpost: action.payload,
      };
    case SET_SELECTED_PREMIUM:
      return {
        ...state,
        selectedPremium: action.payload,
      };
    default:
      return state;
  }
}

const setPassword = (password: string, passwordConfirmation: string) => (dispatch, getState) => {
  dispatch(change('quote', 'password', password));
  dispatch(change('quote', 'passwordConfirmation', passwordConfirmation));
};

const submitForm = (formValues,
                    calculate = false,
                    fromReduxFormValues = false,
                    patch = false) => (dispatch, getState) => {
  const state: IStore = getState();
  const { branding, quote } = state;
  let values = formValues ? formValues : quote.application;

  if (fromReduxFormValues) {
    values = getFormValues('quote')(state);
  }

  let brokerId = null;

  if (branding.trackingLink && branding.trackingLink.status === 'active') {
    brokerId = branding.trackingLink.id;
  }

  if (!values.channel) {
    values.channel = { key: branding.channel.key };
  }

  if (values.payment) {
    if (values.payment.cardExpiryDate && values.payment.cardExpiryDate.length === 5) {
      const expiry = values.payment.cardExpiryDate.split('/');
      values.payment.cardExpiry = expiry[0] + '/' + '20' + expiry[1];
    }
  }

  dispatch(isSubmitting(true))

  const promise = quote.application.id ?
    api.updatePolicyApplication(values, { calculate, patch })
    : api.createPolicyApplication(values, branding.channel.id, brokerId);

  return promise
    .then((application: IPolicyApplication) => {
      dispatch(checkMedicalDecline(application.premiums, application.id, application.quoteReference));
      forEach(application, (value, key) => dispatch(change('quote', key, value)));
      dispatch(setApplication(application));
      dispatch(isSubmitting(false))
      return application;
    }).catch((error) => {
      dispatch(isSubmitting(false))
      return error.response;
    });
};

const saveQuote = (calculate = false, formValues = null) => (dispatch, getState) => {
  const state: IStore = getState();
  const { branding, quote } = state;
  const values: IPolicyApplication = formValues ? formValues : getFormValues('quote')(state);

  dispatch(isSubmitting(true))
  let channelId = branding.channel.id;

  if (get(values, 'channelName', '') === 'Call Centre' &&
    get(branding, 'channel.name', '') === 'Admiral Direct') {
    const channels = get(quote, 'channels', [])
    const channel = find(channels, ['name', 'Call Centre'])
    if (channel.id) {
      channelId = channel.id
    }
  }

  const promise = api.saveQuote(values, channelId, values.brokerId, { calculate });

  return promise
    .then((application: IPolicyApplication) => {
      forEach(application, (value, key) => dispatch(change('quote', key, value)));
      const campaignCode = get(application, 'campaignCode', null);
      dispatch(setApplication(application));
      dispatch(setCampaignCode(campaignCode));
      dispatch(isSubmitting(false))
      return application?.id
    }).catch((error) => {
      dispatch(isSubmitting(false))
      return error.response;
    });
};

const updatePolicyApplication = (calculate: boolean = false, formValues: any = null) => (dispatch, getState) => {
  const state: IStore = getState();
  const values = formValues ? formValues : getFormValues('quote')(state);

  dispatch(isSubmitting(true))
  const promise = api.updatePolicyApplication(values, { calculate, patch: false })

  return promise
    .then((application: IPolicyApplication) => {
      forEach(application, (value, key) => dispatch(change('quote', key, value)));
      dispatch(setApplication(application));
      dispatch(isSubmitting(false))
    }).catch((error) => {
      dispatch(isSubmitting(false))
      return error.response;
    });
};

const retrieveQuote = (quote) => (dispatch) => {
  return dispatch({ type: RETRIEVE_QUOTE, payload: { quote } });
};

const isRetrievingQuote = (isRetrieving: boolean) => (dispatch) => {
  return dispatch({ type: IS_RETRIEVE_QUOTE, payload: isRetrieving });
};

const retrieveQuoteFromPolicy = (policy: IPolicy) => (dispatch) => {
  const communications = policy.metadata.communications ? policy.metadata.communications : { post: false };
  const destinations = policy.metadata.destinations ? policy.metadata.destinations : [];
  const region = policy.metadata.region ? policy.metadata.region : null;
  const quoteObject = new QuoteObject(
    communications,
    destinations,
    mapOptions(policy.metadata),
    region,
    ['tripDeclaration'],
    mapTravellers(policy.metadata),
    policy.metadata.scheme_type,
    'new business',
    policy.metadata.traveller_group,
  );
  return dispatch({ type: RETRIEVE_QUOTE_FOR_JOURNEY, payload: { quoteObject } });
};

const mapTravellers = (policyMetadata: any): ITraveller[] => {
  const travellers: ITraveller[] = [];

  if (policyMetadata.adults && policyMetadata.adults.length) {
    forEach(policyMetadata.adults, (adultTraveller, key) => {
      if (!key) {
        travellers[0] = {
          id: adultTraveller.id,
          title: adultTraveller.title,
          firstName: adultTraveller.first_name,
          lastName: adultTraveller.last_name,
          dob: adultTraveller.dob ? moment(adultTraveller.dob).format('DD/MM/YYYY') : '',
          type: 'adult',
          screening: null,
          screeningSessionId: null,
          marketingOptions: adultTraveller.marketing_options,
          email: adultTraveller.email,
          phone: adultTraveller.phone1,
          address: adultTraveller.address,
          metadata: adultTraveller.metadata,
        };
      } else {
        travellers.push({
          id: adultTraveller.id,
          title: adultTraveller.title,
          firstName: adultTraveller.first_name,
          lastName: adultTraveller.last_name,
          dob: adultTraveller.dob ? moment(adultTraveller.dob).format('DD/MM/YYYY') : '',
          screening: null,
          screeningSessionId: null,
          marketingOptions: adultTraveller.marketing_options,
          metadata: adultTraveller.metadata,
          type: 'adult',
        });
      }
    });
  }

  if (policyMetadata.children && policyMetadata.children.length) {
    forEach(policyMetadata.children, (childTraveller, key) => {
      travellers.push({
        id: childTraveller.id,
        title: childTraveller.title,
        firstName: childTraveller.first_name,
        lastName: childTraveller.last_name,
        dob: childTraveller.dob ? moment(childTraveller.dob).format('DD/MM/YYYY') : '',
        screening: null,
        screeningSessionId: null,
        marketingOptions: undefined,
        metadata: null,
        type: 'child',
      });
    });
  }
  return travellers;
};

const mapOptions = (policyMetadata: any): IOptionMap => {
  const options: IOptionMap = {};
  options.winter_sports = policyMetadata.options ? policyMetadata.options.winter_sports : false;
  options.cruise_cover = policyMetadata.options ? policyMetadata.options.cruise_cover : false;
  options.hazardous_activities = policyMetadata.options ? policyMetadata.options.hazardous_activities : false;
  options.gadget_cover = policyMetadata.options ? policyMetadata.options.gadget_cover : false;
  options.excess_waiver = policyMetadata.options ? policyMetadata.options.excess_waiver : false;
  return options;
};

const checkVoucherCode = (campaignCode) => (dispatch, getState) => {
  const state: IStore = getState();
  const { branding } = state;
  return api.checkCampaignCode(campaignCode, branding.channel.id)
    .then((response) => {
      if (response.data) {
        const preventDisplay = !!(response.data.attributes.metadata &&
          response.data.attributes.metadata.prevent_display);
        const voucherEnded = response.data.attributes && response.data.attributes.status === 'ended';
        const regexVoucherCode = !!(response.data.attributes && response.data.attributes.regex_voucher_code);

        if (voucherEnded) {
          throw Error('Voucher code ended');
        }

        dispatch(voucherCodeSuccess({ preventDisplay, regexVoucherCode }));
        dispatch(setCampaign(response.data.id));
        return response.data;
      }

      // setTimeout((() => {
      //   dispatch({ type: VOUCHER_CODE_RESET });
      // }), 3000);
    })
    .catch((error) => {
      dispatch({ type: VOUCHER_CODE_ERROR });
      setTimeout((() => {
        dispatch(resetVoucherCode());
      }), 3000);
    });
};

export const resetVoucherCode = () => (dispatch) => {
  dispatch({ type: VOUCHER_CODE_RESET });
}

const voucherCodeSuccess = (payload) => {
  return {
    type: VOUCHER_CODE_SUCCESS,
    payload: {
      preventDisplay: payload.preventDisplay,
      regexVoucherCode: payload.regexVoucherCode,
    },
  };
};

const setCampaign = (campaign) => (dispatch, getState) => {
  dispatch(change('quote', 'campaign', campaign));
};

const dispatchVoucherCode = (campaignCode) => (dispatch, getState) => {
  dispatch({ type: DISPATCH_CAMPAIGN_CODE, payload: campaignCode });
  dispatch(checkVoucherCode(campaignCode));
};

const calculate = (formValues = null, fromReduxFormValues = false) => (dispatch, getState) => {
  const state: IStore = getState();
  const { quote, branding } = state;
  let values = formValues ? formValues : quote.application;

  dispatch(setIsCalculating(true));

  if (fromReduxFormValues) {
    values = getFormValues('quote')(state);
  }

  if (!values.channel) {
    values.channel = { key: branding.channel.key };
  }

  const isAggregator = branding.channel.channelType === 'AGG';
  const patch = quote.application.quote && quote.application.quote.quoteType === 'renewal' && isAggregator;
  return api.updatePolicyApplication(values, { calculate: true, patch })
    .then((application: IPolicyApplication) => {
      dispatch(setIsCalculating(false));
      dispatch(setApplication(application));
      dispatch(setQuotes(application.quotes));
      dispatch(setCustomer(application.customer));
      storeUpSellIncreaseRatios(dispatch, application.quotes, application);
      storeCPRResponse(dispatch, application.quotes, application);
      dispatch(checkMedicalDecline(application.premiums, application.id, application.quoteReference));
      return application;
    });
};

const calculateRenewalQuotes = (productId, fromReduxFormValues = false) => (dispatch, getState) => {
  const state: IStore = getState();
  const { quote, branding } = state;
  let values: IPolicyApplication = quote.application;

  dispatch(setIsCalculating(true));

  if (fromReduxFormValues) {
    values = getFormValues('quote')(state);
  }

  if (!values.channel) {
    values.channel = { key: branding.channel.key };
  }

  return api.calculateRenewalQuotes(values, productId)
    .then((premiums: IPremium[]) => {
      dispatch(setIsCalculating(false));

      const renewalQuotes: IQuotes = {};
      forEach(premiums, (premium) => {
        const coverLevel = get(premium, 'scheme.coverLevel');
        const schemeType = get(premium, 'scheme.schemeType');
        if (coverLevel && schemeType && premium) {
          renewalQuotes[coverLevel] = { [schemeType]: premium };
        }
      });

      dispatch(setQuotes(renewalQuotes));
      dispatch(checkMedicalDecline(premiums, values.id));
      return premiums;
    });
};

const calculateQuote = (
  productId,
  fromReduxFormValues = false,
  updateValues = false,
  options: IQuoteCalculateOptions
) => (dispatch, getState) => {
  const state: IStore = getState();
  const { quote, branding } = state;
  let values: IPolicyApplication = quote.application;

  dispatch(setIsCalculating(true));

  if (fromReduxFormValues) {
    values = getFormValues('quote')(state);
  }

  if (!values.channel) {
    values.channel = { key: branding.channel.key };
  }

  return api.calculateQuote(values, productId, options)
    .then((premiums: IPremium[]) => {
      const selectedPremium: IPremium = find(premiums, ['scheme.id', values.schemeId]);
      const hasMedicalAdded = quoteHasMedical(null, get(values, 'travellers'));

      if (selectedPremium) {
        let hasMedicalDecline = false;
        let hasReferralRule = false;
        const isSignPosted = get(selectedPremium, 'isSignPosted', false);
        const referrals = get(selectedPremium, 'referrals', []);
        if (filter(referrals, ['is_medical', true]).length > 0) {
          hasMedicalDecline = true;
        }

        if (referrals.length && !hasMedicalDecline) {
          hasReferralRule = true;
        }

        const signPosted = isSignPosted && hasMedicalAdded || hasMedicalDecline
        Promise.resolve(dispatch(setSignpost({ isSignposted: signPosted, isMedical: hasMedicalDecline }))).then(() => {
          if (updateValues && get(selectedPremium, 'gross')) {
            dispatch(setQoteApplicationPremium([selectedPremium]))
          }
          dispatch(setIsCalculating(false));
          return { premiums, isSignposted: signPosted, isMedical: hasMedicalDecline, hasReferralRule };
        });

        return { premiums, isSignposted: signPosted, isMedical: hasMedicalDecline, hasReferralRule }
      }
    });
};


const calculateUpsellQuote = (values) => (dispatch, getState) => {
  const formValues: ICoverDetailsForm = values;
  return axios.post(
    url(Endpoints.CUSTOMER_MTA_CALCULATE_PREMIUMS, { params: { id: formValues.data.attributes.id } }),
    formValues,
    { headers: { 'Content-Type': 'application/vnd.api+json' } },
  ).then((response) => {
    return response.data.meta;
  }).catch(() => {
    console.log('Calculation error');
  });
};

const checkMedicalDecline = (premiums: IPremium[], applicationId: string, quoteReference: string = '') => (dispatch) => {

  let hasMedicalDecline = false;
  let hasReferralRule = false;
  const referrals = [];
  forEach(premiums, (premium) => {
    const premiumReferrals = get(premium, 'referrals', []);
    if (!isEmpty(premiumReferrals)) {
      forEach(premiumReferrals, (premiumReferral) => {
        referrals.push(premiumReferral)
      })
    }
  });

  if (filter(referrals, ['is_medical', true]).length > 0) {
    hasMedicalDecline = true;
  }

  if (filter(referrals, ['is_medical', null]).length > 0) {
    hasReferralRule = true;
  }

  if (hasReferralRule) {
    Heap.handleCalculationReferrals(quoteReference, hasMedicalDecline, hasReferralRule)
  }

  if (hasMedicalDecline) {
    Heap.handleCalculationReferrals(quoteReference, hasMedicalDecline, hasReferralRule)
    if (applicationId) {
      dispatch(addNotes('Medical decline – directory signposted', applicationId));
    }
  }
};

const addNotes = (text: string, applicationId: string) => (dispatch, getState) => {
  return api.addNotes(text, applicationId);
};

const storeCPRResponse = (dispatch, quotes, application) => {
  if (application.premiums.length < 1) {
    return;
  }

  const firstPremium = application.premiums[0];
  if (firstPremium) {
    const cprResponse = firstPremium.information.cprResponse || null;
    dispatch({ type: STORE_CPR_RESPONSE, payload: cprResponse });
  }
};

const storeUpSellIncreaseRatios = (dispatch, quotes, application) => {
  if (Object.keys(quotes).length < 2) {
    return;
  }

  const annual = 'Annual Multi Trip';
  const annualUpSellIncrease = {};

  Object.keys(quotes).forEach((coverLevel) => {
    const quote = quotes[coverLevel][annual];
    annualUpSellIncrease[coverLevel] = quote.information.upSellIncreaseRatio || 1;
  });

  dispatch({ type: STORE_UP_SELL_INCREASE_RATIOS, payload: annualUpSellIncrease });
};

const setBrokerId = (brokerId) => ({ type: SET_BROKER_ID, payload: brokerId });
export const setDestinations = (destinations) => ({ type: SET_DESTINATIONS, payload: { destinations } });
export const setStateCities = (stateCities) => ({ type: SET_STATE_CITIES, payload: { stateCities } });
export const setOccupations = (occupations) => ({ type: SET_OCCUPATIONS, payload: { occupations } });
export const setIndustries = (industries) => ({ type: SET_INDUSTRIES, payload: { industries } });
export const setChannels = (channels) => ({ type: SET_CHANNELS, payload: { channels } });
export const setFraudCheck = (payload) => ({ type: SET_FRAUD_CHECK, payload });
const setApplication = (application: IPolicyApplication) => ({ type: SET_APPLICATION, payload: { application } });
const isSubmitting = (isSubmitting: boolean) => ({ type: SET_IS_SUBMITTING, payload: isSubmitting });
const setCustomer = (customer: ICustomer) => ({ type: SET_CUSTOMER, payload: { customer } });
const setQuotes = (quotes: IQuotes): IQuoteAction => ({ type: SET_QUOTES, payload: { quotes } });
const setPolicy = (policy): IQuoteAction => ({ type: SET_POLICY, payload: { policy } });
const reset = (): IQuoteAction => ({ type: RESET });
const hasTerminalCondition = (): IQuoteAction => ({ type: HAS_TERMINAL_CONDITION });
const updateApplication = (values: any): IQuoteAction => ({ type: UPDATE_APPLICATION, payload: values });
const error = (message: any): IQuoteAction => ({ type: SET_ERROR_MESSAGE, payload: { message } });
const setQoteApplicationPremium = (premium: IPremium[]): IQuoteAction => ({
  type: SET_QUOTE_APPLICATION_PREMIUM,
  payload: premium
})

const setCampaignCode = (campaignCode: string): IQuoteAction => ({
  type: SET_CAMPAIGN_CODE,
  payload: { campaignCode },
});

const addStepToWizard = (step: string, formOnly = true): IQuoteAction => (dispatch, getState) => {
  const state: IStore = getState();
  const currentSteps = state.quote.application.steps;

  if (currentSteps.indexOf(step) > -1) {
    return null;
  }

  const steps = [
    ...currentSteps,
    step,
  ];

  dispatch(change('quote', 'steps', steps));

  if (!formOnly) {
    dispatch({ type: SET_STEPS, payload: { steps } });
  }
};

const setIsCalculating = (isCalculating: boolean): IQuoteAction =>
  ({ type: SET_IS_CALCULATING, payload: { isCalculating } });

const loadDestinations = () => (dispatch) => {
  return api.listDestinations()
    .then((destinations) => {
      dispatch(setDestinations(destinations));

      return destinations;
    });
};

const loadStateCities = () => (dispatch) => {
  return api.listStateCities()
    .then((stateCities) => {
      dispatch(setStateCities(stateCities));

      return stateCities;
    });
};

const loadChannels = () => (dispatch) => {
  return api.listChannels()
    .then((channels) => {
      dispatch(setChannels(channels));

      return channels;
    });
};

const loadOccupations = () => (dispatch) => {
  return;
  return api.listOccupations()
    .then((occupations) => {
      dispatch(setOccupations(occupations));

      return occupations;
    });
};

const loadIndustries = () => (dispatch) => {
  return;
  return api.listIndustries()
    .then((industries) => {
      dispatch(setIndustries(industries));

      return industries;
    });
};

const fraudCheck = (id: string) => (dispatch, getState) => {
  dispatch(setFraudCheck(null));
  const blackbox: IFraudCheckRequest = {
    id,
    blackbox: (window.IGLOO && window.IGLOO.getBlackbox) ? window.IGLOO.getBlackbox().blackbox : null,
  };
  return api.fraudCheck(blackbox)
    .then((data) => {
      dispatch(setFraudCheck(data.meta));
      return data;
    });
};

const lapseRenewal = (id: string, formValues) => (dispatch, getState) => {
  return api.lapseRenewal(id, formValues)
    .then((data) => {
      return data;
    });
};

const unlapseRenewal = (id: string) => (dispatch, getState) => {
  return api.unlapseRenewal(id)
    .then((data) => {
      return data;
    });
};

const purchase = (application) => (dispatch, getState) => {
  return api.issuePolicyApplication(application)
    .then((data) => {
      dispatch(setPolicy(data));
      return data;
    });
};

const complete = (data: ICompleteData) => (dispatch) => {
  // Remove any potential landing page cookies
  axios.post('/complete-landing', {}, { baseURL: '' }).catch(() => null);

  // Fire complete action
  dispatch(({
    type: COMPLETE,
    payload: {
      ...data,
    },
  }));
};

const completeDocuments = (data: ICompleteData) => ({
  type: COMPLETE_DOCUMENTS,
  payload: {
    ...data,
  },
});

export const getInitValues = (product: IProduct) => {
  const tripType = '';
  const groupType = '';

  return {
    ...cloneDeep(initialState.application),
    tripType,
    groupType,
    // campaignCode,
  };
};

const initFromProduct = (product: IProduct, quoteState: IQuoteState, brandingState: IBrandingState) => {
  const data = getInitValues(product);
  return {
    type: INIT,
    payload: {
      ...data,
    },
  };
};

const recalculateScreening = (formName = '', shouldCreateNewStore = true) => (dispatch, getState) => {
  const form = formName ? formName : 'quote';
  const application: IPolicyApplication = getFormValues(form)(getState());

  if (application.id && application.id.length === 0) {
    // No point in attempting to recalculate if there is no policy application id
    return;
  }

  // Add a return type here if the screening session is healix 🚑

  application.travellers.forEach((traveller, key) => {
    if (!traveller.screeningSessionId) {
      return;
    }

    dispatch(recalculateScore(
      traveller.screeningSessionId,
      application.region,
      application.destinations,
      (screening, screeningId) => {
        dispatch(change(form, `travellers[${key}].screeningSessionId`, screeningId));
        dispatch(change(form, `travellers[${key}].screening`, screening));
      },
      shouldCreateNewStore
    ));
  });
};

const pollPolicyDocuments = (documentsPollToken) => (dispatch) => {
  return api.documentsPoll(documentsPollToken);
};

const setSteps = (steps) => ({ type: SET_QUOTE_STEPS, payload: steps });

const setWaitForPurchaseToComplete = (wait) => ({ type: SET_WAIT_FOR_PURCHASE_TO_COMPLETE, payload: wait });
const setSignpost = (data) => ({ type: SET_SIGNPOST, payload: data });
const setSelectedPremium = (premium) => ({ type: SET_SELECTED_PREMIUM, payload: premium });

const initRenewal = (selectedQuote: IPolicyApplication, returnUrl: string = '') => (dispatch, getState) => {
  if (!get(selectedQuote, 'signposted')) {
    selectedQuote.signposted = { medicalLoad: false, medicalDecline: false }
  }

  const campaignCode = get(selectedQuote, 'campaignCode', null);

  Promise.all([
    Promise.resolve(dispatch(reset())),
    Promise.resolve(dispatch(destroy('quote'))),
    Promise.resolve(dispatch(setQuotes({}))),
    Promise.resolve(dispatch(setApplication(selectedQuote))),
    Promise.resolve(dispatch(setCampaignCode(campaignCode))),
    Promise.resolve(dispatch(resetVoucherCode())),
    Promise.resolve(dispatch(setSignpost({ isSignposted: false, isMedical: false })))
  ]).then(() => {
    if (returnUrl) {
      dispatch(push(returnUrl));
    } else {
      return true;
    }
  })
}

export const getPolicyInfo = (policyId: string) => {
  return (dispatch, getState) => {
    return axios.get(
      url(Endpoints.CUSTOMER_POLICY_GET_INFO, { params: { id: policyId } }),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log(error)
    });
  }
}

export const getPaymentFailure = (renewalReference: string) => {
  return (dispatch, getState) => {
    return axios.get(
      url(Endpoints.RENEWAL_PAYMENT_FAILURE, { params: { reference: renewalReference } }),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      return {
        eventName: get(response, 'data.data.attributes.event_name'),
        paymentMethodId: get(response, 'data.data.attributes.metadata.active_payment_method_id'),
        errorMessage: get(response, 'data.data.attributes.metadata.error'),
      };
    })
  }
}

export const retrievePolicy = (encryptedId: string) => {
  return (dispatch) => {
    return api.retrievePolicy(encryptedId)
      .then((data) => {
        dispatch(isRetrievingQuote(true))
        const policy = data.attributes;
        const communications = policy.metadata.communications ? policy.metadata.communications : { post: false };
        const destinations = policy.metadata.destinations ? policy.metadata.destinations : [];
        const region = policy.metadata.region ? policy.metadata.region : null;
        const quoteObject = new QuoteObject(
          communications,
          destinations,
          mapOptions(policy.metadata),
          region,
          ['tripDeclaration'],
          mapTravellers(policy.metadata),
          policy.metadata.scheme_type,
          'new business',
          policy.metadata.traveller_group,
        );
        return dispatch({ type: RETRIEVE_QUOTE_FOR_JOURNEY, payload: { quoteObject } });
      }).catch((error) => {
        dispatch(push('/'));
      });
  }
}

export const retrieveQuoteFromId = (encryptedId: string) => {
  return (dispatch) => {
    return api.retrieveQuoteFromId(encryptedId)
      .then((data) => {
        dispatch(isRetrievingQuote(true))
        const quote = data.attributes;
        const communications = quote.metadata.communications ? quote.metadata.communications : { post: false };
        const destinations = quote.metadata.destinations ? quote.metadata.destinations : [];
        const region = quote.metadata.region ? quote.metadata.region : null;
        const quoteObject = new QuoteObject(
          communications,
          destinations,
          mapOptions(quote.metadata),
          region,
          ['tripDeclaration'],
          mapTravellers(quote.metadata),
          quote.metadata.scheme_type,
          'new business',
          quote.metadata.traveller_group,
        );
        return dispatch({ type: RETRIEVE_QUOTE_FOR_JOURNEY, payload: { quoteObject } });
      }).catch((error) => {
        dispatch(push('/'));
      });
  }
}

export const initQuoteFromCustomer = (encryptedId: string) => (dispatch) => {
  return api.initQuoteFromCustomer(encryptedId)
    .then((data) => {
      dispatch(isRetrievingQuote(true))
      if (!isEmpty(data)) {
        const quoteObject: IPolicyApplication = cloneDeep(initialState.application);
        quoteObject.travellers[0].title = get(data, 'title', '');
        quoteObject.travellers[0].firstName = get(data, 'firstName', '');
        quoteObject.travellers[0].lastName = get(data, 'lastName', '');
        quoteObject.travellers[0].phone = get(data, 'phone', '');
        quoteObject.travellers[0].dob = get(data, 'dob', '');
        quoteObject.travellers[0].email = get(data, 'email', '');
        quoteObject.travellers[0].address = get(data, 'address', '');
        return dispatch({ type: RETRIEVE_QUOTE_FOR_JOURNEY, payload: { quoteObject } });
      }
    }).catch((error) => {
      return error
    });
}

export const updatePolicy = (policyId: string, data: any) => (dispatch, getState) => {
  return api.updatePolicy(policyId, data)
    .then((data) => {
      return data;
    })
}

export const actions: IQuoteActions = {
  submitForm,
  saveQuote,
  updatePolicyApplication,
  retrieveQuote,
  isRetrievingQuote,
  retrieveQuoteFromPolicy,
  initFromProduct,
  initRenewal,
  isSubmitting,
  loadDestinations,
  loadStateCities,
  loadOccupations,
  loadIndustries,
  calculate,
  calculateRenewalQuotes,
  calculateQuote,
  purchase,
  complete,
  completeDocuments,
  reset,
  pollPolicyDocuments,
  hasTerminalCondition,
  addStepToWizard,
  recalculateScreening,
  setPassword,
  setApplication,
  setQuotes,
  setCustomer,
  updateApplication,
  error,
  checkVoucherCode,
  dispatchVoucherCode,
  setBrokerId,
  setCampaignCode,
  resetVoucherCode,
  setSteps,
  loadChannels,
  fraudCheck,
  setWaitForPurchaseToComplete,
  lapseRenewal,
  unlapseRenewal,
  setSignpost,
  setSelectedPremium,
  addNotes,
  getPolicyInfo,
  updatePolicy,
  getPaymentFailure,
  calculateUpsellQuote,
  retrievePolicy,
  retrieveQuoteFromId,
  initQuoteFromCustomer
};
