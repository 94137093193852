import * as React from 'react';
import { deleteDeclaration, editDeclaration } from '../../../../../../services/healix/reducer';
import { getIncludedResource } from '../../../../../../data/api/util';
import { Button, Col, Row } from 'react-bootstrap';

export default class DeclaredConditions extends React.Component<any, any> {
  private handleDelete = (declaration) => () => {
    const { dispatch } = this.props;

    dispatch(deleteDeclaration(declaration.id, declaration.attributes.conditions[ 0 ]));
  }

  private handleEdit = (declaration) => () => {
    const { dispatch } = this.props;

    dispatch(editDeclaration(declaration.id));
  }

  public render() {
    const { screening } = this.props;

    const declaredConditions = getIncludedResource(
      screening.declaredConditions.data,
      screening.declaredConditions.included,
      'conditions',
      true,
    );

    return (
      <div className="declared-conditions">
        {declaredConditions.length ? declaredConditions.map((declaration, declarationIndex) => {
          return (
            <div key={declarationIndex}>
              <Row className="condition">
                <Col xs={8}>
                  {declaration.attributes.conditions.map((condition, conditionIndex) => {
                    if (conditionIndex === 0) {
                      return (
                        <h4 key={conditionIndex} className="primary-condition">
                          {condition}
                        </h4>
                      );
                    }
                    return (
                      <span key={conditionIndex} className="linked-condition">{condition}</span>
                    );
                  })}
                </Col>
                <Col xs={4}>
                  <div className="reverse-buttons">
                    <Button
                      bsStyle="primary"
                      bsSize="sm"
                      className="pull-right margin-bottom declared-conditions-button remove"
                      disabled={screening.isDeleting === declaration.id}
                      onClick={this.handleDelete(declaration)}
                    >
                      Remove
                    </Button>
                    <Button
                      bsStyle="primary"
                      bsSize="sm"
                      className="pull-right margin-bottom declared-conditions-button edit"
                      disabled={screening.isEditing === declaration.id}
                      onClick={this.handleEdit(declaration)}
                    >
                      Edit
                    </Button>
                   </div>
                </Col>
              </Row>
            </div>
          );
        }) : null}
      </div>
    );
  }
}
