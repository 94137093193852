import React = require('react');
import { useState } from 'react';

export const MentalHealthPanel = () => {
  const [showText, setShowText] = useState(false);

  const handleOnClick = () => {
    setShowText(!showText)
  }

  return (
    <div className={'information-panel-container'}>

      <div className={'dropdown-box'} onClick={handleOnClick}>
        <div className={'dropdown-box-text'}>information on mental health conditions or more support</div>
        <div className={'dropdown-box-icon'}>{showText ? 'Less Information' : 'More Information'}</div>
      </div>

      {showText && (
        <div className={'information-panel-body'}>
          <div style={{ marginTop: '10px' }}>
            <b>Mental Health Conditions:</b> If you would like information about travel insurance and mental health
            conditions or
            more support to complete your insurance application, visit our dedicated page <a
            href={'https://www.admiral.com/magazine/guides/travel/travel-and-mental-health-guide'}
            target="_blank">here
          </a>.
          </div>
        </div>
      )}

    </div>
  )
}
