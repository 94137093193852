import * as React from 'react';
import { setAnswer } from '../../../../../../services/healix/reducer';
import { getIncludedResource } from '../../../../../../data/api/util';
import { Radio } from 'react-bootstrap';

export default class BooleanQuestion extends React.Component<any, any> {
  public state = {
    answer: '',
  };

  public componentWillReceiveProps(props) {
    const options = getIncludedResource(props.question, props.screening.declaration.included, 'options', true);

    options.map((option) => {
      if (option.attributes.isSelected && this.state.answer !== option.attributes.number) {
        this.setState({ answer: option.attributes.number });
      }
    });
  }

  private handleRadioChange = (e) => {
    const { condition, question, dispatch } = this.props;
    if (e.target === undefined) {
      return;
    }
    const answer = e.target.value;
    this.setState({ answer });
    dispatch(setAnswer(condition, question, { value: answer }));
  }

  public render() {
    const { question, screening } = this.props;
    const optionsResource = getIncludedResource(question, screening.declaration.included, 'options', true);
    const { answer } = this.state;

    return (
      <div className="row form-horizontal question dynamic-row">
        <div className="col-xs-12">
          <label className="control-label">{question.attributes.text}</label>
          {question.attributes.info ? (
            <p className="text-info" style={{ fontSize: '14px' }}>
              {question.attributes.info}
              <br/>
            </p>
          ) : ''}
          <div className="screening-radio-question">
            {optionsResource.map((option, i) => {
              const inputId = option.id;

              return (
                <div key={i}>
                  <Radio
                    id={inputId}
                    value={option.attributes.number}
                    onChange={this.handleRadioChange}
                    checked={answer === option.attributes.number}
                  >
                    <div className="btn-quote-option" />
                    {option.attributes.text}
                  </Radio>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
