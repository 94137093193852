import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuoteWizardNavigation from './components/QuoteWizardNavigation';
import { II18nMessages } from '../../../../services/branding/models';
import { withRouter } from 'react-router';
import { flow, forEach } from 'lodash';
import { Col, Navbar, Row } from 'react-bootstrap';
import { IHeaderActions } from './models';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { actions as headerActions } from './reducer';
import { IChannel } from '../../../../data/api/models';
import { destroy } from 'redux-form';
import { IQuoteActions } from '../../../../services/quote/models';
import { push } from 'react-router-redux';
import { IStore } from '../../../../redux/IStore';
import PortalAreaTopNav from './PortalAreaTopNav';

interface IHeaderProps {
  classes: any;
  isDrawerOpened: boolean;
  i18n: II18nMessages;
  actions: {
    push: (route: string) => void;
    header: IHeaderActions;
    quote: IQuoteActions;
    destroy(formName: string);
  };
  steps: any;
  location: any;
  channel: IChannel;
  openContactUsModal: boolean;
  hideContactUsSelection: boolean;
}

const RENEWAL_PRODUCT_CODE = 'TRAV-RENEW';

class Header extends React.Component<IHeaderProps, any> {

  private renderWizardNavigation = () => {
    const { steps, location, i18n } = this.props;

    let currentStep = '';
    let isInQuoteProcess = false;

    forEach(steps, (step) => {
      if (step.route === location.pathname) {
        currentStep = step.key;
        isInQuoteProcess = true;
      }
    });

    if (isInQuoteProcess) {
      return (
        <QuoteWizardNavigation i18n={i18n} steps={steps} currentStep={currentStep}/>
      );
    }
  }

  private renderLogo = () => {
    const { channel } = this.props;

    if (channel.channelType === 'AGG' && channel.channelLogo) {
      return (
        <div className="logo-container">
          <div className="channel-logo-solo">
            <img src={channel.channelLogo} alt={channel.name}/>
          </div>
        </div>
      );
    }

    if (channel.channelType === 'AGG' && !channel.channelLogo && channel.additionalLogo
      && channel.products[0].productCode !== RENEWAL_PRODUCT_CODE) {
      return (
        <div className="logo-container">
          <div className="logo-link">
            <img src={require('./img/admiral_logo.png')} alt="Admiral"/>
          </div>
          <div className="logo-divider"/>
          <div className="agg-logo">
            <img src={channel.additionalLogo} alt={channel.name}/>
          </div>
        </div>
      );
    }

    return (
      <div className="logo-container">
        <div className="logo-link">
          <img src={require('./img/admiral_logo.png')} alt="Admiral"/>
        </div>
        <div className="logo-divider"/>
        <div className="logo-title">
          Travel
        </div>
      </div>
    );
  }

  public render() {
    const { channel, hideContactUsSelection } = this.props;
    return (
      <Navbar className="new-header">
        <header>
          <PortalAreaTopNav channel={channel} hideContactUsSelection={hideContactUsSelection}/>
          <div className="container header-container">
            <Row className="header-flex-box">
              <Col xs={12}>
                {this.renderLogo()}
              </Col>
            </Row>
            {this.renderWizardNavigation()}
          </div>
        </header>
      </Navbar>
    );
  }
}

export default flow([
  connect(
    (state: IStore) => ({
      isDrawerOpened: state.header.isDrawerOpened,
      i18n: state.branding.i18n,
      channel: state.branding.channel,
    }),
    (dispatch) => ({
      actions: {
        push: bindActionCreators(push, dispatch),
        header: bindActionCreators({ ...headerActions }, dispatch),
        destroy: bindActionCreators(destroy, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(Header);
