import * as React from 'react';
import { cloneDeep, filter, flow, forEach, isEmpty, map, mapValues, size, get } from 'lodash';
import { IBrandingState, II18nMessages } from '../../../../services/branding/models';
import { Button, Col, Form, HelpBlock, Row } from 'react-bootstrap';
import {
  destroy,
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
  InjectedFormProps,
  reduxForm,
  reset,
  submit,
} from 'redux-form';
import { isRequired } from '../../../../util/validators';
import './style.scss';
import TravellersMedical from './components/TravellersMedical';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IDeclaration, IProduct, ITraveller } from '../../../../data/api/models';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import FieldFormControl from '../../../../components/FieldFormControl/index';
import * as Commonmark from 'react-commonmark';
import confirm from 'react-confirm2';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import mapMedicalQuestions from '../../../../helpers/medicalDeclaratonQuestions';
import { MedicalInformation } from './components/MedicalInformation';

interface IMedicalDeclaration extends InjectedFormProps {
  i18n: II18nMessages;
  product: IProduct;
  pristine: boolean;
  quote: IQuoteState;
  submitting: boolean;
  onBack: () => void;
  dispatch: any;
  traveller: ITraveller;
  salesChannel: string;
  medicalDeclaration: IDeclaration[];
  travellers: ITraveller[];

  renderDefinition(name: string, useProvidedString?: string): any;

  actions: {
    submit(name: string);
    quote: IQuoteActions;
    reset(formName: string);
    destroy(formName: string);
  };
  branding: IBrandingState;
  synchronousError: any;
}

interface IMedicalDeclarationState {
  isScreening: boolean;
  isTerminal: boolean;
  isAnyModalOpen: boolean;
  unableToProvideQuote: boolean;
}

class MedicalDeclaration extends React.Component<IMedicalDeclaration, IMedicalDeclarationState> {
  public state = {
    isScreening: false,
    isTerminal: false,
    isAnyModalOpen: false,
    unableToProvideQuote: false,
  };

  public componentWillMount() {
    const { product, change, medicalDeclaration } = this.props;

    if (size(medicalDeclaration) === 0) {
      change(
        'medicalDeclaration',
        mapValues(cloneDeep(
            product.metadata.medical_declaration_questions),
          (declaration) => ({ ...declaration, answer: undefined }),
        ),
      );
    }
  }

  private shouldShowScreeningSection = () => {
    const { medicalDeclaration } = this.props;

    if (!medicalDeclaration) {
      return false;
    }

    const questions = filter(medicalDeclaration, (question) => question.type === 'question');
    const unanswered = filter(questions, (question) =>
      question.answer === undefined && question.question_type === 'boolean',
    );
    const trueAnswers = filter(questions, (question) => question.answer === true &&
      !get(question, 'should_not_screen', false));
    return unanswered.length === 0 && trueAnswers.length > 0;
  }

  private setIsScreening = (isScreening: boolean) => {
    this.setState({
      isScreening,
    });
  }

  private renderMedicalQuestion = (item, key) => {
    const { renderDefinition, synchronousError, submitFailed } = this.props;
    const error = get(synchronousError, `medicalDeclaration.${key}.answer`);

    let component = null;

    if (item.question_type === 'boolean') {
      component = [
        (
          <Col xs={6} key={1}>
            <Field
              name={`medicalDeclaration[${key}].answer`}
              type="radio"
              format={null}
              value={true}
              complexValue={true}
              className="answer"
              validationMessage={'single'}
              component={FieldFormControl}
              validate={[isRequired]}
            >
              <div className="medical-btn yes">
                Yes
              </div>
            </Field>
          </Col>
        ),
        (
          <Col xs={6} key={2}>
            <Field
              name={`medicalDeclaration[${key}].answer`}
              type="radio"
              format={null}
              value={false}
              complexValue={true}
              className="answer"
              validationMessage={'single'}
              component={FieldFormControl}
              validate={[isRequired]}
            >
              <div className="medical-btn no">
                No
              </div>
            </Field>
          </Col>
        ),
        (
          <>
            {error && submitFailed && (
              <Row style={{ textAlign: 'center' }}>
                <Col>
                  <div className={error ? 'has-error' : ''}>
                    {<HelpBlock>{error}</HelpBlock>}
                  </div>
                </Col>
              </Row>
            )}
          </>
        ),
      ];
    }

    if (item.question_type === 'number') {
      component = (
        <div>
          <Field
            name={`medicalDeclaration[${key}].answer`}
            className="answer-number"
            component={FieldFormControl}
          />
        </div>
      );
    }

    return (
      <div className="medical-question medical-section" key={key}>
        <Row>
          <Col sm={7}>
            <Commonmark source={item.content}/>
            <div className="remove-margins-from-children">
              {renderDefinition(item.true_key, item.help_text)}
            </div>
          </Col>
          <Col sm={5}>
            {component}
          </Col>
        </Row>
      </div>
    );
  }

  private renderMedicalHeader = (item, key) => {
    return (
      <div key={key}>
        <p>
          {item.content}
        </p>
      </div>
    );
  }

  public renderQuestions() {
    const { medicalDeclaration, salesChannel, dispatch, change } = this.props;

    // TODO: hack for old medical questions
    if (!isEmpty(medicalDeclaration)) {
      const mappedQuestions = mapMedicalQuestions(salesChannel, medicalDeclaration);
      dispatch(change('medicalDeclaration', mappedQuestions));
    }

    const displayKeys = [];

    forEach(medicalDeclaration, (declaration: IDeclaration, key) => {
      const answer = declaration.answer;
      const questionType = declaration.question_type;

      if (questionType === 'boolean' && answer === 'true') {
        if (declaration.next_key) {
          displayKeys.push(declaration.next_key);
        }

        if (declaration.true_key) {
          displayKeys.push(declaration.true_key);
        }
      }

      if (questionType === 'boolean' && answer === 'false') {
        if (declaration.next_key) {
          displayKeys.push(declaration.next_key);
        }

        if (declaration.false_key) {
          displayKeys.push(declaration.false_key);
        }
      }
    });

    return (
      <div className="medical-questions-container">
        {map(medicalDeclaration, (question: any, key) => {
          let component;

          if (question.display === false && displayKeys.indexOf(key) === -1) {
            return null;
          }

          if (question.type === 'header') {
            component = this.renderMedicalHeader(question, key);
          }

          if (question.type === 'question') {
            component = this.renderMedicalQuestion(question, key);
          }

          return component;
        })}
      </div>
    );
  }

  private checkIfConditionWasDeclared = () => {
    const { travellers, medicalDeclaration } = this.props;

    const questions = filter(medicalDeclaration, (question) => question.type === 'question');
    const unanswered = filter(questions, (question) =>
      question.answer === undefined && question.question_type === 'boolean',
    );
    const trueAnswers = filter(questions, (question) => question.answer === true &&
      !get(question, 'should_not_screen', false));
    const travellerScreening = filter(travellers, (traveller) => traveller.screening);
    const travellersLength = travellers.length;
    const shouldScreen = unanswered.length === 0 && trueAnswers.length > 0;

    if (!shouldScreen) {
      this.checkForErrorsBeforeSubmit();
    }

    if (shouldScreen && travellerScreening.length === travellersLength) {
      this.checkForErrorsBeforeSubmit();
    }

    if (shouldScreen && travellerScreening.length < travellersLength && travellerScreening.length === 0) {
      this.setState({ isAnyModalOpen: true });
      confirm('Finish Medical Screening', {
        description: (
          <span>
            <h4>Warning - You cannot continue without declaring a medical condition.</h4>
            Please click on 'Add Conditions' button to add your conditions.
            <br/>
          </span>
        ),
        done: () => {
          this.setState({ isAnyModalOpen: false });
          return;
        },
        close: () => {
          this.setState({ isAnyModalOpen: false });
          return;
        },
      });
    }

    if (shouldScreen && travellerScreening.length < travellersLength && travellerScreening.length > 0) {
      this.checkForErrorsBeforeSubmit();
    }
  }

  private checkForErrorsBeforeSubmit = () => {
    const { actions, synchronousError } = this.props;
    Promise.resolve(actions.submit('quote')).then(() => {
      if (synchronousError && !isEmpty(synchronousError)) {
        window.scrollTo(0, 0);
      }
    });
  }

  private isRenewal() {
    return this.props.quote.application.quoteType === 'renewal';
  }

  public render() {
    const {
      i18n,
      product,
      handleSubmit,
      medicalDeclaration,
      submitting,
      valid,
      change,
      submitFailed,
      branding,
    } = this.props;
    const { metadata } = product;
    const { isAnyModalOpen, unableToProvideQuote } = this.state;

    if (!medicalDeclaration) {
      return false;
    }

    return (
      <Form onSubmit={handleSubmit} autoComplete="off">
        {isRequired && submitFailed && !valid && (
          <div className="error-block-container" id="error-container">
            <h1>Whoops!</h1>
            <p>Missing something? Please check the areas marked in orange before you continue.</p>
          </div>
        )}
        {!unableToProvideQuote ? (
          <div className="steps-container">
            <div className="section-title">
              <h2>Medical Declaration</h2>
            </div>

            <MedicalInformation/>

            <div className="medical-section-group">

              {this.renderQuestions()}

              <div className="medical-condition-container" hidden={!this.shouldShowScreeningSection()}>
                <div className="section-title">
                  <h2>Your medical condition(s)</h2>
                </div>

                {(this.isRenewal() && branding.channel.channelType !== 'AGG') && (
                  <div className="section">
                    {/* tslint:disable-next-line:max-line-length */}
                    <Commonmark className="important"
                                source={product.metadata.definitions.direct_renewal_medical_conditions}/>
                  </div>
                )}

                <FieldArray
                  name="travellers"
                  i18n={i18n}
                  change={change}
                  component={TravellersMedical}
                  questions={metadata.medical_declaration_questions}
                  setIsScreening={this.setIsScreening}
                  rerenderOnEveryChange={true}
                />
              </div>

              <Row>
                <Col>
                  <div className="btn-bar pr-15" hidden={this.state.isScreening === true}>
                    <Button
                      bsStyle="primary"
                      id="continue"
                      onClick={this.checkIfConditionWasDeclared}
                      className="pull-right"
                      bsSize="lg"
                      disabled={submitting || (submitFailed && !valid) || isAnyModalOpen}
                    >
                      CONTINUE
                    </Button>
                  </div>
                </Col>
              </Row>

              <div className="toc section-important" style={{ marginTop: '25px' }}
                   hidden={this.state.isScreening === true}>
                By clicking continue you agree to our <a
                href="https://www.admiral.com/insurance/your-policy/conditions-of-use.php?&contentOnly=1&_ga=2.7793211.828839540.1508235797-2125578306.1459951786&_gac=1.217769634.1505922935.EAIaIQobChMI_rW5lpC01gIVzrztCh1dwA5aEAAYASAAEgJX9PD_BwE"
                target="_blank">conditions of use </a>
                and <a
                href="https://www.admiral.com/your-privacy-and-security/?&contentOnly=1&_ga=2.7793211.828839540.1508235797-2125578306.1459951786&_gac=1.217769634.1505922935.EAIaIQobChMI_rW5lpC01gIVzrztCh1dwA5aEAAYASAAEgJX9PD_BwE"
                target="_blank">privacy statement</a>
              </div>

            </div>
          </div>
        ) : (
          <div className="steps-container unable-box">
            <h1>Sorry, we are unable to provide a quote</h1>
            <h4>
              We are unable to provide any cover if you or anyone on your policy have been placed
              on a waiting list for treatment or investigation, or been given a terminal prognosis.
            </h4>
          </div>
        )}
      </Form>
    );
  }
}

let selector;

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state) => {
      const medicalDeclaration = selector(state, 'medicalDeclaration');
      const travellers = selector(state, 'travellers');
      const steps = selector(state, 'steps');
      const salesChannel = selector(state, 'salesChannel');
      const synchronousError = getFormSyncErrors('quote')(state);

      return {
        medicalDeclaration,
        travellers,
        steps,
        synchronousError,
        salesChannel,
      };
    },
    (dispatch) => ({
      dispatch,
      actions: {
        submit: bindActionCreators(submit, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        reset: bindActionCreators(reset, dispatch),
        destroy: bindActionCreators(destroy, dispatch),
      },
    }),
  ),
])(MedicalDeclaration);
