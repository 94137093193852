import * as React from 'react';
import { FieldsProps, formValueSelector } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';
import { ITraveller } from '../../../../../../data/api/models';
import { finishScreening, startScreening } from '../../../../../../services/healix/reducer';
import Healix from '../../../Healix';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

interface ITravellersMedicalProps {
  fields: FieldsProps<any>;

  change(field: string, value: any): void;

  setIsScreening(isScreening: boolean): void;

  travellers: ITraveller[];
  destinations: string[];
  region: string[];
  healix: any;

  dispatch();

  startScreening(travellerIndex, screeningId, region, destinations, callback);

  finishScreening(callback);
}

interface ITravellersMedicalState {
  key: string | number;
  loading: boolean;
  sessionId: string;
  traveller: ITraveller;
  isScreening: boolean;
  url: string;
}

class TravellersMedical extends React.Component<ITravellersMedicalProps, ITravellersMedicalState> {
  public state = {
    key: null,
    loading: false,
    sessionId: null,
    traveller: null,
    isScreening: false,
  };

  private handleStartScreening = (traveller: ITraveller, key) => () => {
    this.setState({
      loading: true,
      traveller,
      key,
    });

    const { region, destinations, startScreening } = this.props;

    const hasConditions = traveller.screening && traveller.screening.meta.conditions.length > 0;

    startScreening(key, hasConditions ? traveller.screeningSessionId : null, region, destinations, (sessionId) => {
      this.props.setIsScreening(true);
      this.setState({
        loading: false,
        isScreening: true,
        sessionId,
      });
    });

    // startScreeningCega(hasConditions ? traveller.screeningSessionId : null)
    //   .then(({id: sessionId, url}) => {
    //     this.props.setIsScreening(true);
    //     this.setState({
    //       loading: false,
    //       isScreening: true,
    //       sessionId,
    //       url,
    //     });
    //   });
  }

  private getTravellerIndex = (id: string, fields) => {
    let travellerIndex = -1;
    const travellers = fields.getAll();

    travellers.forEach((traveller, index) => {
      if (traveller.id === id) {
        travellerIndex = index;
      }
    });

    if (travellerIndex === -1) {
      console.error('Couldn\'t find traveller', travellers, id, travellerIndex);
    }

    return travellerIndex;
  }

  private handleFinishScreening = () => {
    const { change, finishScreening, fields } = this.props;
    const { /*sessionId,*/ traveller } = this.state;

    const key = this.getTravellerIndex(traveller.id, fields);

    finishScreening((screening, screeningId) => {
      change(`travellers[${key}].screeningSessionId`, screeningId);
      change(`travellers[${key}].screening`, screening);

      this.props.setIsScreening(false);
      this.setState({
        traveller: null,
        sessionId: null,
        isScreening: false,
      });

      this.forceUpdate();
    });

    // finishScreeningHealix(sessionId)
    //   .then((data) => {
    //     const hasConditions = data.meta.conditions.length > 0;
    //
    //     change(`travellers[${key}].screeningSessionId`, hasConditions ? sessionId : null);
    //     change(`travellers[${key}].screening`, hasConditions ? data : null);
    //
    //     this.props.setIsScreening(false);
    //     this.setState({
    //       key: null,
    //       traveller: null,
    //       sessionId: null,
    //       isScreening: false,
    //     });
    //   })
    //   .catch(() => {
    //     this.props.setIsScreening(false);
    //     this.setState({
    //       key: null,
    //       traveller: null,
    //       sessionId: null,
    //       isScreening: false,
    //     });
    //   });
  }

  private renderConditions = (conditions: string[]) => {
    if (conditions.length === 0) {
      return (
        <p className="screened-conditions">No Conditions Screened</p>
      );
    }

    return conditions.map((condition, key) => <li key={key}>{condition}</li>);
  }

  // private renderScores = (scores) => {
  //   let listScores = '';
  //   return Object.keys(scores).map((region) => {
  //     return listScores += ' Region ' + region + ': ' + scores[region];
  //   });
  // }

  private renderTravellers = (travellerName, key) => {
    const { travellers } = this.props;
    const traveller: ITraveller = travellers[key];
    const hasConditions = !!traveller.screeningSessionId;

    return (
      <div key={key} className="section-group">
        <div className="section">
          <strong>
            {traveller.firstName} {traveller.lastName}
            {/*&nbsp;Scores:&nbsp;*/}
            {/*{this.renderScores(get(traveller, 'screening.meta.regions', {}))}*/}
          </strong>
        </div>
        <div className="section">
          <Row>
            <Col xs={8} className="full-width-xs">
              {this.renderConditions(hasConditions ? traveller.screening.meta.conditions : [])}
            </Col>
            <Col xs={4} className="text-right full-width-xs">
              <Button
                bsStyle="primary"
                onClick={this.handleStartScreening(traveller, key)}
                disabled={this.state.loading}
              >
                {hasConditions ? 'Edit' : 'Add'} Conditions
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  // private renderScreening = () => {
  //   return (
  //     <div className="traveller-medical">
  //       <MedicalScreening
  //         isModal={false}
  //         url={this.state.url}
  //         sessionId={this.state.sessionId}
  //         out={false}
  //         onFinish={this.handleFinishScreening}
  //       />
  //
  //       <div className="clearfix" />
  //     </div>
  //   );
  // }

  private renderHealixScreening = () => {
    const { destinations, region, change, healix, dispatch, travellers } = this.props;
    const { key } = this.state;
    const travellerName = travellers[key].firstName + ' ' + travellers[key].lastName;

    const callback = (screening, screeningId) => {
      change(`travellers[${key}].screeningSessionId`, screeningId);
      change(`travellers[${key}].screening`, screening);
    };

    return (
      <div className="traveller-medical">
        <Healix
          travellerName={travellerName}
          index={key}
          screening={healix}
          finishScreening={this.handleFinishScreening}
          dispatch={dispatch}
        />

        <div className="clearfix"/>
      </div>
    );
  }

  public render() {
    const { fields } = this.props;
    const { isScreening } = this.state;

    return (
      <div ref={`conditions`}>
        {!isScreening && fields.map(this.renderTravellers)}
        {isScreening && this.renderHealixScreening()}
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state) => {
      const travellers = selector(state, 'travellers');
      const region = selector(state, 'region');
      const destinations = selector(state, 'destinations');

      return {
        travellers,
        region,
        destinations,
        healix: state.healix,
      };
    },
    (dispatch) => ({
      startScreening: bindActionCreators(startScreening, dispatch),
      finishScreening: bindActionCreators(finishScreening, dispatch),
      dispatch,
    }),
  ),
])(TravellersMedical);
