import * as React from 'react';
import { finishDeclaration } from '../../../../services/healix/reducer';
import ConditionSearch from './scenes/ConditionSearch/index';
import ConditionQuestions from './scenes/ConditionQuestions/index';
import DeclaredConditions from './scenes/DeclaredConditions/index';
import { Button } from 'react-bootstrap';
import { getIncludedResource } from '../../../../data/api/util';

export default class HealixSession extends React.Component<any, any> {
    // static propTypes = {
    //   index: PropTypes.number.isRequired,
    //   screening: PropTypes.object.isRequired,
    //   dispatch: PropTypes.func.isRequired,
    //   finishScreening: PropTypes.func.isRequired,
    // };

    public state = {
        isAboutToScreen: '',
    };

    private finishDeclaration = () => {
        const { dispatch } = this.props;

        dispatch(finishDeclaration());
    }

    private conditionChange = (condition) => {
        this.setState({ isAboutToScreen: condition });
    }

    public render() {
        const { index, screening, dispatch, finishScreening, travellerName } = this.props;
        const { isAboutToScreen } = this.state;
        const conditions = getIncludedResource(
            screening.declaration.data,
            screening.declaration.included,
            'conditions',
            true,
        );

        return (

            <div className="screening-session-healix">
                <div className="traveller-name">
                    <strong>{travellerName}</strong>
                </div>
                <div className="screening-body">
                {conditions.length ? conditions.map((condition, i) => {
                    return (
                        <ConditionQuestions key={i} condition={condition} dispatch={dispatch}
                                            screening={screening}/>
                    );
                }) : (
                    <ConditionSearch
                        index={index}
                        dispatch={dispatch}
                        conditionProps={this.state.isAboutToScreen}
                        conditionChange={this.conditionChange}
                        screening={screening}
                    />
                )}

                {!screening.complete
                && screening.declaration.data.attributes.state === 'ready' && (
                    <div className="row declaration-actions dynamic-row">
                        <div className="col-xs-12">
                            <Button
                                bsStyle="primary"
                                id="continue"
                                disabled={screening.isSubmitting}
                                className="pull-right"
                                onClick={this.finishDeclaration}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                )}

                {screening.complete && (
                    <div className="row dynamic-row">
                        <div className="col-xs-12">
                            <DeclaredConditions dispatch={dispatch} screening={screening}/>
                        </div>
                    </div>
                )}

                {screening.complete && !isAboutToScreen && (
                    <div className="row declaration-actions dynamic-row">
                        <div className="col-xs-12">
                            <Button
                                className="pull-right"
                                bsStyle="primary"
                                disabled={(screening.isSubmitting && screening.index === index)}
                                onClick={finishScreening}
                            >
                                Finish Screening
                            </Button>
                        </div>
                    </div>
                )}
                </div>
            </div>
        );
    }
}
