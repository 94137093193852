import * as React from 'react';
import Select from 'react-select';
import { setAnswer } from '../../../../../../services/healix/reducer';
import { getIncludedResource } from '../../../../../../data/api/util';

export default class SingleChoiceQuestion extends React.Component<any, any> {
  public state = {
    answer: '',
  };

  public componentWillReceiveProps(props) {
    const options = getIncludedResource(props.question, props.screening.declaration.included, 'options', true);

    options.map((option) => {

      if (option.attributes.isSelected && this.state.answer !== option.attributes.number) {
        this.setState({ answer: option.attributes.number });
      }
    });
  }

  private handleSetAnswer = (answer) => {
    const { condition, question, dispatch } = this.props;

    dispatch(setAnswer(condition, question, answer));

    this.setState({ answer });
  }

  public render() {
    const { index, screening, question } = this.props;
    const optionsResource = getIncludedResource(question, screening.declaration.included, 'options', true);
    const { answer } = this.state;

    const options = optionsResource.map((option) => {
      return {
        value: option.attributes.number,
        label: option.attributes.text,
      };
    });

    return (
      <div className="single-choice-question">
        <div className="form-group">
          <label className="control-label">{question.attributes.text}</label>
          {question.attributes.info ? (
            <p className="text-info" style={{ fontSize: '14px' }}>
              {question.attributes.info}
              <br/>
            </p>
          ) : ''}
          <Select
            name={'single-choice-question-' + index}
            options={options}
            value={answer}
            clearable={false}
            onChange={this.handleSetAnswer}
          />
        </div>
      </div>
    );
  }
}
