import { IPremium } from '../models';
import { get } from 'lodash';

export function fromJsonApi(data): IPremium[] {
  return data.map((item) => {
    const information = get(item, 'information');
    const scheme = get(item, 'scheme');
    const schemeBenefits = scheme.config
      ? scheme.benefits
      : item.benefits;
    const premiumBeforeAdjustments = get(item, 'premium_before_adjustments');
    return {
      id: get(item, 'id'),
      schemeType: get(item, 'scheme.scheme_type'),
      calculatedAt: get(item, 'calculated_at'),
      validUntil: get(item, 'valid_until'),
      currency: get(item, 'premium.currency', 'GB'),
      tax: get(item, 'premium.tax'),
      principal: get(item, 'premium.principal'),
      grossExAdditional: get(item, 'premium.gross_ex_additional'),
      gross: get(item, 'premium.gross'),
      discount: get(item, 'premium.discount'),
      taxRate: get(item, 'rates.tax_rate'),
      isSignPosted: get(item, 'premium.is_signposted'),
      benefits: get(item, 'benefits', []).map((benefit) => ({
        name: benefit.name,
        text: benefit.text,
        description: benefit.description,
        inverse: benefit.inverse,
        excess: benefit.excess,
        displayName: benefit.display_name,
        shortName: benefit.short_name,
        subBenefits: benefit.sub_benefits && benefit.sub_benefits.map((subBenefit) => ({
          excess: subBenefit.excess,
          name: subBenefit.name,
          text: subBenefit.text,
        })),
      })),
      information: information && {
        allBenefits: information.all_benefits,
        standardExcess: information.standard_excess,
        hasGroups: information.has_groups,
        boltOns: information.bolt_ons,
        upgrade: information.upgrade && {
          schemeId: information.upgrade.scheme,
          amount: information.upgrade.amount,
          coverLevel: information.upgrade.coverLevel,
        },
        maxTripDuration: information.max_trip_duration,
        medicalCoverForCovid: information.medical_cover_for_covid || false,
        preSignpostScheme: information.is_pre_signpost_scheme || false,
        claimCalculated: information.claim_calculated || null,
        startDate: information.start_date,
        region: information.region,
        tripType: information.scheme_type,
        fraudCheck: information.fraud_check || false,
        showRewardsBanner: information.show_rewards_banner || false,
        upSellIncreaseRatio: information.up_sell_increase_ratio || undefined,
        cprResponse: information.cpr_response || undefined,
      },
      declines: get(item, 'declines'),
      referrals: get(item, 'referrals'),
      errors: get(item, 'errors'),
      scheme: scheme && {
        id: scheme.id,
        coverLevel: scheme.cover_level,
        status: scheme.status,
        logo: scheme.logo,
        name: scheme.name,
        schemeType: scheme.scheme_type,
        insurer: scheme.insurer,
        documents: item.scheme.documents,
        includedOptions: scheme.included_options || [],
        allBenefits: schemeBenefits && schemeBenefits.map((benefit) => ({
          name: benefit.name,
          text: benefit.text,
          description: benefit.description,
          inverse: benefit.inverse,
          excess: benefit.excess,
          displayName: benefit.display_name,
          shortName: benefit.short_name,
          subBenefits: benefit.sub_benefits && benefit.sub_benefits.map((subBenefit) => ({
            excess: subBenefit.excess,
            name: subBenefit.name,
            text: subBenefit.text,
          })),
        })),
      },
      campaigns: get(item, 'campaigns'),
      premiumBeforeAdjustment: premiumBeforeAdjustments && {
        currency: premiumBeforeAdjustments.currency,
        principal: premiumBeforeAdjustments.principal,
        medical: premiumBeforeAdjustments.medical,
        tax: premiumBeforeAdjustments.tax,
        gross: premiumBeforeAdjustments.gross,
        exTax: premiumBeforeAdjustments.ex_tax,
      },
    };
  });
}
